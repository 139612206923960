@import '../../sassStyles/_variables.scss';

.green-btn {
  color: $white;
  font-family: Untitled;
  text-transform: none;
  font-size: 1rem;
  background-color: $forestGreen;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;

  &:hover {
    background-color: $black;
  }
}

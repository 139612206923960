@import '../../sassStyles/_mixins';
.season-section {
  margin-top: 50px;

  .season-picker {
    margin: 0;
  }
}
//   &_card {
//     display: flex;

//     width: 1200px;
//     min-height: 400px;

//     margin-bottom: 5%;

//     &:nth-child(2n) {
//       flex-direction: row-reverse;
//     }

//     &-text {
//       width: 600px;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;

//       background-color: $bagnesBlue;
//       border: 1px solid greenyellow;
//       color: $white;

//       padding: 10%;
//       p {
//       }
//     }

//     &-image {
//       width: 600px;
//       background-color: aqua;

//       border: 4px solid salmon;

//       img {
//         width: 100%;
//         height: 100%;
//       }
//     }
//   }
// }
// @include tablet {
// }

//     &_card {
//       flex-direction: column;
//       width: 100%;
//       //   height: 100%;

//       &:nth-child(2n) {
//         flex-direction: column;
//       }
//       &-text {
//         flex: 1;
//       }

//       &-image {
//         flex: 1;

//         img {
//           //   width: 100%;
//           //   height: 100%;
//         }
//       }

@import '../../sassStyles/_variables.scss';

.faq {
  p {
    margin-bottom: 3rem;
  }

  a {
    color: $goomahBlue;

    &:hover {
      color: $goomahGreen;
    }
  }
}

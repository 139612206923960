@import '../_mixins.scss';
/*
     * ===================================================================================================
     *                                          HERO
     * ===================================================================================================
*/
.hero {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 140px);

  // height: 100vh;
  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    // z-index: 999; // FIX Overlaps navbar mobile menu
  }
}

/*
     * ===================================================================================================
     *                                     SECTION (TEXT)
     * ===================================================================================================
*/

.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  // margin: 0 10%;
  box-sizing: border-box;

  p {
    margin: 0 15%;
  }

  &__full-width {
    width: 100%;
  }

  &__section {
    padding: 50px 10%;
    // padding: 5% 20%;
    // &-text {
    //   margin: 100px 0;
    // }

    // DELETE - move to home and rename?
    &-image {
      margin-top: 100px;
    }
  }

  img {
    width: 100%;
  }
}

@include media(tablet, phone) {
  .container {
    // margin: 0 5%;

    &__section {
      padding: 50px 20px;

      // DELETE - move to home and rename?
      &-image {
        margin-top: 50px;
      }
    }
    p {
      margin: 0;
      line-height: toRem(30);
      letter-spacing: 0.5px;
    }
  }
}

/*
     * ===================================================================================================
     *                                       CARD - TWO COLUMN LAYOUT 
     * ===================================================================================================
*/
.card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  // padding-bottom: 5%;
  &__text,
  &__image {
    flex: 1 0 calc(25% - 10px);
    &:after {
      content: '';
      display: block;
      // padding-bottom: 100%;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      //EDIT % or px?
      // padding: 0 50px;
    }
  }

  &__image {
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
}

@include media(tablet, phone) {
  .card {
    // padding-bottom: 5%;

    &__text,
    &__image {
      flex: 1 0 100%;
    }

    &__text {
      padding: 10% 0;

      p {
        margin: 0 25px 25px 25px;
        font-size: toRem(18);
        line-height: toRem(30);
      }
    }

    &__image {
      width: 100%;
      height: 100%;
    }
  }
}

@include tablet {
  .card {
    &__text,
    &__image {
      height: 450px;
      width: 450px;
    }
  }
}

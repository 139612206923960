@import '../../sassStyles/_mixins.scss';
@import '../../sassStyles/_variables.scss';

.nav {
  // border: 1px solid $goomahGreen;
  display: flex;
  // align-items: center;
  justify-content: center;
  font-family: 'Untitled Sans';
  background-color: $goatBeige;
  height: 100px;
  .season-wrapper {
    transform: translateX(2rem);
  }

  .burger-season-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // BURGER / CLOSE
    .hamburger-react {
      z-index: 999;
      margin: 0;
      margin-right: 1rem;
    }
  }

  // OPEN MENU
  &-menu {
    display: none;
    height: 100%;
    width: 100%;
    position: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    text-align: center;
    font-size: toRem(20);

    // line-height: 3rem;
    text-transform: uppercase;

    background-color: $goatBeige; // maybe opacity

    &.expanded {
      .nav-links {
        margin-bottom: 1rem;
      }
      .menu-icon {
        top: 0;
        right: 2rem;
      }

      // &.hidden {
      //   display: flex;
      // }
      display: flex;
    }
  }

  // NAV LOGO PHONE - CLOSED
  .nav-logo-phone {
    transform: translateX(2rem);

    // position: absolute;
    // top: 0;
    // margin-top: 2rem;

    // &.hidden {
    //   display: none;
    // }
    // &.open {
    //   display: flex;
    // }
    img {
      width: 150px;
      height: auto;
    }
  }

  // OPEN MENU LOGO
  &-logo {
    position: absolute;
    top: 0;

    &.open {
      margin-top: 1.5rem;
    }
    // z-index: 999;

    &.hidden {
      display: none;
    }

    // DELETE OPEN & I JSX
    // &.open {
    //   display: flex;
    //   border: 10px solid dodgerblue;
    // }

    img {
      width: 150px;
      height: auto;
    }
  }

  ul {
    list-style-type: none;

    li {
      margin: 0;
      white-space: nowrap;
    }

    .nav-links {
      text-decoration: none;
      color: $black;

      display: inline-block;
      padding: 2%;
      transition: all 1s;
      position: relative;

      &.active {
        border-bottom: 1px solid $black;
      }

      // &.inactive:hover::before {
      // content: '';
      // position: absolute;
      // bottom: 0;
      // left: 0;
      // height: 0;
      // width: 0;
      // // z-index: 1;
      // border-bottom: 1px solid $black;
      // animation: grow 0.4s linear;
      // animation-fill-mode: forwards;
      // }
      &:hover {
        color: $bagnesBlue;
      }

      // @keyframes grow {
      //   100% {
      //     width: 100%;
      //   }
      // }
      &.inactive:hover {
        // color: $black;
      }

      &.hidden {
        display: none;
      }
    }
  }
}

@media (min-width: 880px) {
  .nav {
    padding: 2rem;

    margin: 10px 200px;
    flex-direction: column;
    height: 120px;

    .season-wrapper {
      transform: translateX(0);
    }

    .nav-logo-phone {
      visibility: hidden;
      img {
        display: none;
      }
    }
    // DELETE !!!!
    // .burger-season-wrapper {
    //   visibility: hidden;
    //   img {
    //     display: none;
    //   }
    // }

    &-menu {
      position: relative;
      display: flex;
      font-size: toRem(16);
      // padding: 1rem;

      // DESKTOP LOGO
      .nav-logo {
        margin-top: 1rem;
        position: relative;

        &.phone {
          display: none;
        }
      }

      ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        justify-items: center;
        align-items: center;
        padding: 1rem;
        li {
          width: 100%;
          margin-right: 1rem;
          text-align: center;
        }
      }
    }
    .hamburger-react {
      visibility: hidden;
      display: none;
    }

    .goomah__nav {
      position: absolute;
    }
  }
}

@import './mixins.scss';

/*
     * ===================================================================================================
     *                                       BASE TYPOGRAPHY
     * ===================================================================================================
*/
h1 {
  font-family: 'Untitled Sans';
  font-size: toRem(51);
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
}

h2 {
  font-family: 'Untitled Sans';
  font-size: toRem(51);
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
}

h3 {
  font-family: 'Untitled Sans';
  font-size: toRem(25);
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
}

p {
  font-family: 'Untitled';
  font-size: toRem(20);
  line-height: toRem(30);
  // EDIT MAYBE move align to parent?
  text-align: center;

  // CREATE class componenets?
  .center-justified {
  }
  // text-align: justify;
  // text-align-last: center;
}

a {
  text-decoration: none;
  color: inherit;
}

/*
     * ===================================================================================================
     *                                       PHONE TYPOGRAPHY
     * ===================================================================================================
*/

@include phone {
  h1 {
    font-size: toRem(41);
  }

  h2 {
    font-size: toRem(41);
  }

  p {
    text-align: center;
  }
  // h3 {
  //   font-size: toRem(21);
  // }
}
/*
     * ===================================================================================================
     *                                      IMPORTED FONTS
     * ===================================================================================================
     
*/
@font-face {
  font-family: 'Untitled';
  font-weight: normal;
  font-style: normal;
  src: url(../assets/fonts/untitled/untitled-serif-regular.woff2)
    format('woff2');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                                  'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Untitled';
  font-weight: normal;
  font-style: italic;
  src: url(../assets/fonts/untitled/untitled-serif-regular-italic.woff2)
    format('woff2');
}

@font-face {
  font-family: 'Untitled';
  font-weight: 600;
  font-style: normal;
  src: url(../assets/fonts/untitled/untitled-serif-medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Untitled';
  font-weight: 600;
  font-style: italic;
  src: url(../assets/fonts/untitled/untitled-serif-medium-italic.woff2)
    format('woff2');
}

@font-face {
  font-family: 'Untitled';
  font-weight: bold;
  font-style: normal;
  src: url(../assets/fonts/untitled/untitled-serif-bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Untitled';
  font-weight: bold;
  font-style: italic;
  src: url(../assets/fonts/untitled/untitled-serif-bold-italic.woff2)
    format('woff2');
}

@font-face {
  font-family: 'Untitled Sans';
  font-weight: normal;
  font-style: normal;
  src: url(../assets/fonts/untitled/untitled-sans-regular.otf)
    format('opentype');
}

@font-face {
  font-family: 'Untitled Sans';
  font-weight: normal;
  font-style: italic;
  src: url(../assets/fonts/untitled/untitled-sans-regular-italic.otf)
    format('opentype');
}

@import '_variables';

body {
  font-family: Untitled;
  background-color: $goatBeige;
}

// BUTONS
button {
  cursor: pointer;
}

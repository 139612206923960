// Screen sizes
$maxPhone: 480px;
$minTablet: 481px;
$maxTablet: 887px;
$minDesktop: 886px;

// Colors
$white: #fff;
$black: #000;
$forestGreen: #143426;
$goomahGreen: #159339;
$signalRed: #ff5c00;
$bagnesBlue: #0056bc;
$goomahBlue: #295ff3;
$sunnyYellow: #ffcc00;
$goatBeige: #efece9;

@import '../../sassStyles/_variables.scss';

.banner {
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  height: 50px;
  white-space: nowrap; // Place it on one line
  overflow-x: hidden;
  background-color: $sunnyYellow;

  &__text {
    margin: 0;
    display: inline-block;
    padding-left: 100%;
    animation: marquee 30s linear infinite;
    font-size: toRem(25);
    font-family: 'Untitled Sans';
    text-transform: uppercase;
    color: $black;
  }

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
}

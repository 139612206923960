@import '../../sassStyles/_variables.scss';
@import '../../sassStyles/_mixins.scss';

.slider {
  &__home,
  &__rooms {
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
  &__home {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      position: relative;
      display: block;
      top: 0;
      left: 0;
    }
    .slide-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      text-align: center;
      z-index: 999;
      color: $white;

      // View Rooms
      a {
        font-size: 1.5rem;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__rooms {
    height: 430px;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;

    .slide-title {
      display: none;
    }
  }
}

.slide-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  object-fit: contain;
  position: absolute;
  bottom: 0;
  padding: 1rem;
  // left: 50%;
  // transform: translateX(-50%) translateY(-50%);
  z-index: 999; // FIX Overlaps navbar mobile menu
  color: $white;

  img {
    margin: 0 50px;

    width: 40px;
    &:hover {
      cursor: pointer;
    }
  }

  p {
    margin: 0;
    font-family: 'Untitled Sans';
    width: 50px;
  }
}
@include tablet {
  .slider {
    &__home {
      height: 500px;
    }
    &__rooms {
      height: 300px;
    }
  }
}

@include phone {
  .slider {
    &__home {
      height: 300px;

      .slide-title {
        h2 {
          font-size: toRem(26);
        }
        a {
          font-size: toRem(16);
        }
      }
    }
    &__rooms {
      height: 300px;
    }
  }
  .slide-arrows {
    img {
      margin: 0 30px;

      width: 30px;
    }

    p {
      font-size: toRem(14);
    }
  }
}

@import '../../sassStyles/_mixins.scss';

.rooms {
  h1 {
    font-size: toRem(25);
  }
  &__card-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 550px));
    justify-content: center;
    gap: 20px;
    margin: 0 5%;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
    padding-bottom: 50px;

    //FIX: hover effect changes on image??
    @media (hover: hover) {
      &:hover {
        background-color: $forestGreen;
        color: $white;
      }
    }

    &-text {
      padding: 50px 0;
      transition: 0.8s;

      p {
        margin-top: 1rem;
        font-size: toRem(18);
        line-height: toRem(28);
      }

      &__capacity {
        font-family: 'Untitled Sans';
        text-transform: uppercase;
      }

      // Hover on text => changes "button" link
      @media (hover: hover) {
        &:hover ~ a {
          background-color: $goatBeige;
          color: $forestGreen;
          // FIX add some nice transition
        }
      }
    }

    &-image {
      width: 100%;
    }

    // Book now button
    a {
      display: inline-block;
      margin-top: auto;
      @media (hover: hover) {
        &:hover {
          background-color: $goatBeige;
          color: $forestGreen;
        }
      }
    }
  }

  &__amenities {
    img {
      width: 120px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    h3 {
      margin: 1rem 0 0 0;
    }

    p {
      font-family: 'Untitled Sans';
      font-size: toRem(14);
      text-transform: uppercase;
      display: inline;
    }

    span {
      display: block;
      margin-bottom: toRem(1);
    }
  }
}

@include media(tablet, phone) {
  .rooms {
    &__card-wrapper {
      grid-auto-flow: row;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;

      .rooms__card {
        &-text {
          padding: 2rem 1rem;
        }
      }
    }

    &__amenities {
      padding: 2rem;

      img {
        width: 80px;
      }

      h3 {
        margin-bottom: 1rem;
      }
    }
  }
}

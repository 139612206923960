* {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
}

html,
body {
  height: 100%;
}

@import '../../sassStyles/_mixins';

.explore {
  margin: 0 10%;

  .card {
    padding-bottom: 20px;

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }

    &__text {
      h2 {
        font-size: 3rem;
      }

      p {
        font-size: 1.2rem;
      }
      &.Caffè {
        background-color: $signalRed;

        a:hover {
          color: $goomahGreen;
        }
      }

      &.Burgers {
        background-color: $black;
        color: $white;

        a:hover {
          color: $signalRed;
        }
      }

      &.winter {
        &.Slopes {
          background-color: $forestGreen;
          color: $white;
        }

        &.Town {
          background-color: $bagnesBlue;
          color: $white;
        }

        &.Sky {
          background-color: $sunnyYellow;
        }
      }

      &.summer {
        &.Trails {
          background-color: $bagnesBlue;
          color: $white;
        }

        &.Town {
          background-color: $white;
          color: $forestGreen;
        }

        &.Sky {
          background-color: $sunnyYellow;
        }
      }
    }
  }
}

@include media(tablet, phone) {
  .explore {
    margin: 0 5px;
  }
}

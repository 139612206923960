@import '../../sassStyles/_mixins.scss';
@import '../../sassStyles/_variables.scss';
.slider-home {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dodgerblue solid;
  width: 100%;
  object-fit: contain;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 999; // FIX Overlaps navbar mobile menu

  color: $white;
}
.home {
  margin: 0;

  .fasade-drawing {
    margin: 0;
  }
  .hero {
    // position: relative;
    // top: 0;
    // left: 0;

    &-image {
      object-position: 50% 70%;
    }

    &-logo {
      width: 150px;
    }
  }

  &__image-slider {
    height: 90vh;
  }

  // TWO COLUMN CARD
  .card {
    background-color: $forestGreen;
    color: $white;

    &-link {
      margin-top: 2rem;
      font-size: 1.2rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__ramble {
    display: flex;
    flex-direction: column;

    a {
      margin-top: 1rem;
    }
  }
}
@include media(tablet) {
  .home {
    .content-wrapper {
      p {
      }
    }
  }
}

@include media(tablet, phone) {
  .home {
    .hero {
      &-logo {
        width: 150px;
      }
    }

    &__image-slider {
      height: 100%;
    }
  }
}

@import '../../sassStyles/_mixins.scss';

.footer {
  display: flex;
  flex-direction: column;
  font-size: toRem(25);
  padding-top: 50px;

  // goomah
  &-logo {
    width: 300px;
    height: auto;
    filter: brightness(0%); // TODO fix logo color
  }
  &-goomah {
    background-color: $signalRed;
  }

  &-info {
    display: flex;
    flex-direction: column;
    font-family: 'Untitled Sans';
    text-transform: uppercase;
    text-align: center;

    address {
      display: flex;
      flex-direction: column;
      font-style: normal;
      margin: 50px 0;

      a {
        margin-bottom: 1rem;
      }
    }
  }

  &-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;

    svg {
      font-size: 1.5rem;
    }
  }

  &-links {
    display: flex;
    justify-content: space-evenly;
    align-self: center;
    width: 80%;
    margin: 50px;
    font-size: toRem(20);
  }

  @include media(tablet, phone) {
    &-info {
      font-size: toRem(20);
    }

    &-links {
      flex-direction: column;
      align-items: center;
      // width: 100%;
      margin: 25px 0;
      font-size: toRem(16);
    }
  }
}

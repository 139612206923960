@import '../../sassStyles/_mixins.scss';

.gallery {
  margin: 0 10%;

  &-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 400px));
    grid-template-rows: repeat(3, minmax(300px, 400px));
    justify-content: center;
    gap: 20px;

    @include media(tablet) {
      gap: 3px;
    }
  }

  &-item {
    position: relative;
    display: block;
    // margin-bottom: 2%; /* (100-32*3)/2 */
    // position: relative;
    margin: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      // &:hover {
      //   -webkit-transform: scale(1.5, 1.5);
      //   z-index: 10;
      // }
    }
  }
}

@include media(phone) {
  .gallery {
    margin: 0;

    &-wrapper {
      // grid-auto-flow: row;
      grid-template-columns: 1fr;
      gap: 1px;
    }
    &-item {
      height: 400px;
    }
  }
}
